import firebase from "firebase/app"
import "firebase/auth"

const app = firebase.initializeApp({
  apiKey: "AIzaSyDrJU-fHkCr63qi4wksLE81s3Atbkn8cq4",
  authDomain: "my-auth-bf045.firebaseapp.com",
  databaseURL: "https://my-auth-bf045.firebaseio.com",
  projectId: "my-auth-bf045",
  storageBucket: "my-auth-bf045.appspot.com",
  messagingSenderId: "1009634666697",
  appId: "1:1009634666697:web:f10ddef6061ff401738518",
  measurementId: "G-LHD1X6VYNV"
})

export const auth = app.auth()
export default app
